import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {InputGroup, Table} from "react-bootstrap";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";
import ContainerRowInputEdit from "./UI/ContainerRowInputEdit";
import Icon from "@reacticons/bootstrap-icons";


const ReleaseFormInEdit = (props) => {
    const {store} = useContext(Context);
     const [release, setRelease] = useState(props.release);
    let navigate = useNavigate();
    const intl = useIntl();
    let date = new Date();
    let dateString = date.getFullYear() +"-"+ String(date.getMonth() + 1).padStart(2, '0') +"-"+ String(date.getDate()).padStart(2, '0') ;

    const addNewPost = async (e)  => {
        e.preventDefault()
         const response = await $api.post('/release/save', release);

        if(response.status == '200'){
            navigate('/releases');
        }
    }
    const back = () => {
        navigate(-1);
    }
    const cancelStatus = async (e) => {
        e.preventDefault();
        let _release = {...release};
        _release.status = 5;
        const response = await $api.post('/release/save', _release);

        if(response.status == '200'){
            navigate('/releases');
        }



    }



    const handleChange = (e,name) => {
        let _release = {...release};
        _release[name] = e.target.value;
        setRelease(_release);
    }
    const handleChangeContainers = (e, name, index) => {
        let _release = {...release};
        let _containers = [..._release.containers];
        _containers[index][name] = e.target.value;
        _release.containers = _containers;
        setRelease(_release);

    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {

            addNewPost(e);
        }
    };

    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{intl.messages.order_title_in}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.accept_terminal}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control plaintext readOnly value={intl.messages[store.user.terminal]} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.date_from}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control readOnly value={release.date_from}  type="date" placeholder="" required  />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.date_to}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control  type="date" value={release.date_to}  onChange={(e)=>handleChange(e,'date_to')} placeholder="" min={release.date_from??dateString}  required/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.release}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" placeholder="" value={release.release} onChange={(e)=>handleChange(e,'release')}  />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.applicant}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.user} onChange={(e)=>handleChange(e,'user')}  placeholder="" required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_information}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control as="textarea" value={release.description} onChange={(e)=>handleChange(e,'description')}  rows={3} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.fullContainer}:
                </Form.Label>
                <Col sm="9">
                    <InputGroup className="mb-3">
                        <span className="input-group-text">
                            <Icon name="boxes" className="bigIcon"></Icon></span>
                        <span className="input-group-text">
                             { release.full ?
                                 <Form.Check type="checkbox" value="1" checked="true"   />:
                                 <Form.Check type="checkbox" value="1"  />
                             }
                        </span>

                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.containers_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.type}</th>
                                <th>{intl.messages.number_container}</th>
                                <th>{intl.messages.car_number}</th>
                                <th>{intl.messages.driver}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.containers.map((row, index) =>
                                <ContainerRowInputEdit key={index}
                                                   index={index}
                                                   row={row}
                                                   handleChange = {handleChangeContainers}
                                                   del={release.containers.length > 1 ? true: false}
                                />
                            )}

                            </tbody>
                        </Table>
                    </Row>

                </Col>


            </Form.Group>

            <Row className="mb-3 text-align-right" >
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                    <Button className="mx-md-1 btn-danger " type={"button"} onClick={(e)=>cancelStatus(e)}>{intl.messages.cancel}</Button>
                    <Button className="mx-md-1 " type={"submit"}>{intl.messages.save}</Button>
                </Col>


            </Row>
        </Form>

    );
};

export default ReleaseFormInEdit;
