import React, {useContext} from 'react';
import {Route, Routes} from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import {Context} from "../index";
import Releases from "../pages/Releases";
import CreateRelease from "../pages/CreateRelease";
import Layout from "./Layout";
import EditRelease from "../pages/EditRelease";
import Containers from "../pages/Containers";
import Movings from "../pages/Movings";
import CreateReleaseByContainer from "../pages/CreateReleaseByContainer";
import ViewRelease from "../pages/ViewRelease";
import Movings2 from "../pages/Movings2";
import Bills from "../pages/Bills";
import Repairs from "../pages/Repairs";


const AppRouter = () => {
    const {store} = useContext(Context)

    return (
        store.isAuth
            ?
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/releases" element={<Releases />} />
                    <Route path="/release/new/:type" element={<CreateRelease />} />
                    <Route path="/release/new/out/:id" element={<CreateReleaseByContainer />} />
                    <Route path="/release/:id" element={<EditRelease />} />
                    <Route path="/release/view/:id" element={<ViewRelease />} />
                    <Route path="/containers" element={<Containers />} />
                    <Route path="/movings" element={<Movings2 />} />
                    <Route path="/movings/:id" element={<Movings />} />
                    <Route path="/bills" element={<Bills />} />
                    <Route path="/repairs" element={<Repairs />} />
                </Route>
            </Routes>
            :
            <Routes>
                <Route path="/" element={<Login />} />
            </Routes>

    );
};

export default AppRouter;
