import React, {useContext, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {InputGroup, Table} from "react-bootstrap";
import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";
import ContainerRowOutput from "./UI/ContainerRowOutput";
import {useFetching} from "../hooks/useFetching";
import Icon from "@reacticons/bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Loader from "./UI/Loader/Loader";


const ReleaseFormOut = (props) => {
    const {store} = useContext(Context);
    let navigate = useNavigate();
    const [release, setRelease] = useState(props.release);
    const intl = useIntl();
    const [show, setShow] = useState(false);
    const [containerList, setContainerList] =  useState('');
    const [errorcontainer, setErrorcontainer] = useState([]);
    const [addlist, setAddlist] = useState(false);
    const [save, setSave] = useState(false)
    const [fetchType] = useFetching(async (limit, page) => {
        const response = await $api.get('/containers/type')

        store.setTypeOut(response.data.type);
    })
    let date = new Date();
    let dateString = date.getFullYear() +"-"+ String(date.getMonth() + 1).padStart(2, '0') +"-"+ String(date.getDate()).padStart(2, '0') ;


    const modalClose = () => setShow(false);
    const modalShow = () => {
        setContainerList('')
        setErrorcontainer([])
        setShow(true);
    }

    const handleContainerList = (e) => {
        setContainerList(e.target.value)
    }


    const addContainerList = (e) => {
        e.preventDefault();
        let _release = {...release};
        let _containers = [..._release.containers];
        let newContainers = {};
        let str = containerList;
        let array = str.split(/\s*,\s*|\s*\r\s*|\s*\n\s*/)

        let error = [];


        if(typeof array == 'object') {
            console.log(array);
            console.log(store.containers);
            for (let i in array) {
                if (array[i].length > 4) {

                    if(store.containers.filter(_c => {
                        return _c.number.toLowerCase().includes(array[i].toLowerCase())
                    }))
                    {
                        if (findDoubleNumber(_containers, array[i])) {
                            newContainers = {type: '', number: `${array[i]}`, car_number: '', fio: '', count: 1};
                            _containers.push(newContainers);
                        }
                    }
                }
            }
        }
        setContainerList('')
        let _newContainers = [];

        for(let i in _containers){

            if(_containers[i].number !== ''){
                _newContainers.push(_containers[i]);
            }
        }
        _release.containers = _newContainers;
        setAddlist(true)
        setRelease(_release);

        if(error.length > 0){
            setErrorcontainer(error)
        } else {
            modalClose()
        }

    }



    const findDoubleNumber = (array, value) => {
        for (let i in array){
            if(array[i].number === value){
                return false;
            }
        }
        return true;
    }
    const calcCountContainer = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let count = 0;
        for(let i in _containers){
            count += _containers[i].count * 1
        }

    }
    useEffect(() => {

        fetchType();
        const interval = setInterval(fetchType, 60000);
        return () => clearInterval(interval);
     },[])
    const addNewPost = async (e)  => {
        e.preventDefault()
        setSave(true)
        const response = await $api.post('/release/save', release);
        if(response.status == '200'){

            navigate('/releases');
        } else {
            setSave(false)
        }
    }
    const back = () => {
        navigate('/releases');
    }

    const addContainersRow = () => {
        let _release = {...release};
        let _containers = [..._release.containers];
        let newContainers = {type:'', number:'',count:1, car_number:'', fio:''};
        _containers.push(newContainers);
        _release.containers = _containers;
        setRelease(_release);
        calcCountContainer();
    }

    const handleChangeDelivery = (e) => {
        let car_delivery = 0;
        let car_firm = "";
        if(release.car_delivery === 0){
            car_delivery = 1;
            car_firm = "UNIFLOT";
            e.target.checked = true;
        } else {
            car_delivery = 0
            car_firm = "";
            e.target.checked = false;
        }
        let _release = {...release};
        _release.car_delivery = car_delivery;
        _release.car_firm = car_firm;
        setRelease(_release);
    }
    const rowDelete = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let _release = {...release};
        let _containers = [..._release.containers];
        _containers.splice(index, 1);
        _release.containers = _containers;
        setRelease(_release);
        calcCountContainer();

    }

    const handleChange = (e,name) => {

        let _release = {...release};
        _release[name] = e.target.value;
        setRelease(_release);

    }
    const handleChangeContainers = (value, name, index) => {

        let _release = {...release};
        let _containers = [..._release.containers];
        _containers[index][name] = value;
        _release.containers = _containers;
        setRelease(_release);
        calcCountContainer();

    }


    const cancelStatus = async (e) => {
        e.preventDefault();
        let _release = {...release};
        _release.status = 5;
        const response = await $api.post('/release/save', _release);

        if(response.status == '200'){
            navigate('/releases');
        }



    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {
            addNewPost(e);
        }
    };
    if(save){
        return (
            <Loader />
        )
    }


    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{intl.messages.order_title_out}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.issue_terminal}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control plaintext readOnly value={intl.messages[store.user.terminal]} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.date_from}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control value={release.date_from} onChange={(e)=>handleChange(e,'date_from')} type="date" min={dateString} max={release.date_to} placeholder="" required />

                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.date_to}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="date" value={release.date_to} onChange={(e)=>handleChange(e,'date_to')}  placeholder="" min={release.date_from??dateString} required/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.release}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" placeholder="" value={release.release} onChange={(e)=>handleChange(e,'release')}  required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3 d-none" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.carrier}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.car_firm} onChange={(e)=>handleChange(e,'car_firm')}  placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.applicant}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="text" value={release.user} onChange={(e)=>handleChange(e,'user')}  placeholder="" required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_email}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control type="email" value={release.add_email} onChange={(e)=>handleChange(e,'add_email')}  placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.car_delivery}:
                </Form.Label>
                <Col sm="9">
                    <InputGroup className="mb-3">
                        <span className="input-group-text">
                            <Icon name="truck-flatbed" className="bigIcon"></Icon></span>
                        <span className="input-group-text">
                             { release.car_delivery ?
                            <Form.Check type="checkbox" value="1" checked="true" onChange={(e)=>handleChangeDelivery(e)}   placeholder=""  />:
                             <Form.Check type="checkbox" value="1" onChange={(e)=>handleChangeDelivery(e)}   placeholder=""  />
                             }
                        </span>

                    </InputGroup>
                </Col>
            </Form.Group>
            { release.car_delivery ?

                <Form.Group as={Row} className="mb-3" controlId="formReliase">
                    <Form.Label column sm="3">
                        {intl.messages.agreed_price_per_car}:
                    </Form.Label>
                    <Col sm="9">
                        <InputGroup className="mb-3">
                            <Form.Control type="number" value={release.agreed_price} onChange={(e)=>handleChange(e,'agreed_price')} placeholder="" required />
                            <span className="input-group-text">{props.currency} {intl.messages.per_car}</span>

                        </InputGroup>
                    </Col>
                </Form.Group> : null }
            { release.car_delivery ?
                <Form.Group as={Row} className="mb-3" controlId="formReliase">
                    <Form.Label column sm="3">
                        {intl.messages.delivery_address}:
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control as="textarea" value={release.delivery_address} onChange={(e)=>handleChange(e,'delivery_address')}  placeholder="" required />
                    </Col>
                </Form.Group> :
                null
            }
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_information}
                </Form.Label>
                <Col sm="9">
                    <Form.Control as="textarea" value={release.description} onChange={(e)=>handleChange(e,'description')}  rows={3} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.containers_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.type}</th>
                                <th>{intl.messages.number_container}</th>
                                <th>{intl.messages.quantity}</th>
                                <th>{intl.messages.car_number}</th>
                                <th>{intl.messages.driver}</th>

                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.containers.map((row, index) =>
                                <ContainerRowOutput key={index}
                                                   index={index}
                                                   row={row}
                                                   car_firm={release.car_firm}
                                                   handleChange = {handleChangeContainers}
                                                   delete={rowDelete}
                                                   addlist={addlist}
                                                   del={release.containers.length > 1 ? true: false}
                                />
                            )}

                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mb-3" >
                        <Col sm={{span: 3}}>
                            <Button variant="secondary" onClick={addContainersRow}>{intl.messages.add_container}</Button>
                        </Col>
                        <Col sm={{span: 3}}>
                            <Button variant="secondary" onClick={modalShow}>{intl.messages.list_containers}  </Button>
                        </Col>
                    </Row>
                </Col>
            </Form.Group>

            <Row className="mb-3 text-align-right" >
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                    {release.id ? <Button className="mx-md-1 btn-danger " type={"button"} onClick={(e)=>cancelStatus(e)}>{intl.messages.cancel}</Button>: ''}
                    <Button className="mx-md-1" type={"submit"}>{release.id?intl.messages.save:intl.messages.create}</Button>
                </Col>

            </Row>
            <Modal show={show} onHide={modalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.messages.list_containers}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control id="addContainer" as="textarea" value={containerList} onChange={(e)=>handleContainerList(e)}  rows={6} />
                    <Row className="mt-3 text-align-right">
                        <Col className="mb-3 d-grid gap-2 d-md-block" >
                            <Alert variant="info">

                                {intl.messages.list_containers_message}</Alert>
                            {errorcontainer.length > 0 ?
                                <Alert  variant="danger"> <Alert.Heading>{intl.messages.incorrect_number}</Alert.Heading>
                                    {errorcontainer.join(', ')}</Alert>
                                : null
                            }
                        </Col>


                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(e)=>addContainerList(e)}>
                        {intl.messages.add}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>

    );
};

export default ReleaseFormOut;
