import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ReleaseFormIn from "../components/ReleaseFormIn";
import ReleaseFormOut from "../components/ReleaseFormOut";
import {useFetching} from "../hooks/useFetching";
import $api from "../http";
import Loader from "../components/UI/Loader/Loader";
import ReleaseFormView from "../components/ReleaseFormView";
import {Context} from "../index";
import ReleaseFormOutFEdit from "../components/ReleaseFormOutFEdit";

const ViewRelease = () => {
    const {store} = useContext(Context);
    const type = 'in';
    const {id} = useParams();

    const [release, setRelease] = useState([]);
    const [fetchRelease, isReleaseLoading] = useFetching(async () => {
        const response = await $api.get(`/release/getById/${id}`)

        setRelease(response.data.release);

    })
    useEffect(() => {
        fetchRelease()
    }, [])


    if(isReleaseLoading){
        return(
         <Loader/>
        )
    }
    else if(release.type_release === 'IN') {

            return (
                <ReleaseFormView release={release}/>

            );

    } else if(release.type_release === 'OUT') {
        if(release.cars.length == 0) {
        return (
            <ReleaseFormView release={release} currency={store.currency}/>

        );
        } else {
            if (release.status === 3){
                return (
                <ReleaseFormOutFEdit release={release} currency={store.currency}/>
                )
            } else {
                return (
                <ReleaseFormView release={release} currency={store.currency}/>
                )
            }
        }
    }
};

export default ViewRelease;
