import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";
import {Context} from "../../index";
import {useIntl} from "react-intl";

const ContainerRowInputEdit = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const content = [{'value': 'E', 'name':intl.messages.empty},{'value': 'F', 'name':intl.messages.fullc}]

    const row = props.row;
    const handleChange = (e,name) => {
        props.handleChange(e,name, props.index);
        console.log(props.row)
    }

    const type = store.type;

    return (
        <tr>
            <td>

            </td>

            <td>
                <Form.Select value={row.type} onChange={e => handleChange(e, 'type')}>
                    <option></option>
                    {type.map((s, i) =>
                        <option key={i} value={s.id}>{s.name}</option>
                    )}
                </Form.Select>
            </td>

            <td>
                <Form.Control readOnly className="container_test" value={row.number} type="text" placeholder=""/>
            </td>
            <td>
                <Form.Control type="text" value={row.car_number} onChange={e => handleChange(e, 'car_number')}/>
            </td>
            <td>
                <Form.Control value={row.fio} onChange={e => handleChange(e, 'fio')} type="text" placeholder=""/>
            </td>
            <td>

            </td>
        </tr>
    );
}

export default ContainerRowInputEdit;