import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Icon from "@reacticons/bootstrap-icons";
import {Context} from "../../index";
import {useIntl} from "react-intl";


const ContainerRowInput = (props) => {
    const {store} = useContext(Context);
    const intl = useIntl();
    const content = [{'value': 'E', 'name':intl.messages.empty},{'value': 'F', 'name':intl.messages.fullc}]
    const row = props.row;
    const handleChange = (e,name) => {
        props.handleChange(e.target.value,name, props.index);
    }
    const handleChangeContainer = (e) => {

        if(e.target.value.length > 0 && e.target.value < 11){
            e.target.setCustomValidity("incorect number");
        } else if(store.testContainer(e.target.value) === false){
            e.target.setCustomValidity("incorect number");
        } else {
            e.target.setCustomValidity("");
        }
        handleChange(e,'number')
    }



    const type = store.type;

    return (
        <tr>
            <td>
                {props.index + 1}
            </td>

            <td>
                <Form.Select value={row.type} onChange={e => handleChange(e, 'type')}>
                    <option></option>
                    {type.map((s, i) =>
                        <option key={i} value={s.id}>{s.name}</option>
                    )}
                </Form.Select>
            </td>
            
            <td>
                <Form.Control className="container_test" value={row.number} onChange={e => handleChangeContainer(e)} type="text" placeholder="" required/>
            </td>
            <td>
                <Form.Control type="text" value={row.car_number} onChange={e => handleChange(e, 'car_number')} />


            </td>
            <td>
                <Form.Control  value={row.fio} onChange={e => handleChange(e, 'fio')} type="text" placeholder="" />
            </td>
            <td>
                {!props.del ? '' :
                    <a href="#" className="text-warning" onClick={(e) => props.delete(e, props.index)}>
                        <Icon name="trash"/>
                    </a>
                }
            </td>
        </tr>
    );
}

export default ContainerRowInput;