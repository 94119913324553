import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {InputGroup, Table} from "react-bootstrap";

import $api from "../http";
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {useIntl} from "react-intl";
import ContainerRowOutputEdit from "./UI/ContainerRowOutputEdit";
import Icon from "@reacticons/bootstrap-icons";

const ReleaseFormOutEdit = (props) => {
    const {store} = useContext(Context);
    let navigate = useNavigate();
    const [release, setRelease] = useState(props.release);
    const intl = useIntl();
    const addNewPost = async (e)  => {
        e.preventDefault()
        const response = await $api.post('/release/save', release);
        if(response.status == '200'){

            navigate('/releases');
        }
    }
    const back = () => {
        navigate('/releases');
    }

    const handleChange = (e,name) => {
        let _release = {...release};
        _release[name] = e.target.value;
        setRelease(_release);

    }


    const cancelStatus = async (e) => {
        e.preventDefault();
        let _release = {...release};
        _release.status = 5;
        const response = await $api.post('/release/save', _release);

        if(response.status == '200'){
            navigate('/releases');
        }



    }

    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();
        } else {
            addNewPost(e);
        }
    };

    return (

        <Form noValidate onSubmit={handleSubmit} className="was-validated">
            <h3 className="my-5">{intl.messages.order_title_out}:</h3>

            <Form.Group as={Row} className="mb-3" >
                <Form.Label as={Col} sm="3">
                    {intl.messages.issue_terminal}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control plaintext readOnly value={intl.messages[store.user.terminal]} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateFrom">
                <Form.Label column sm="3">
                    {intl.messages.date_from}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled value={release.date_from}  type="date" placeholder="" required />

                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formDateTo">
                <Form.Label column sm="3">
                    {intl.messages.date_to}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled type="date" value={release.date_to}   placeholder=""  required/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.release}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled type="text" placeholder="" value={release.release} onChange={(e)=>handleChange(e,'release')}  required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.carrier}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled type="text" value={release.car_firm} onChange={(e)=>handleChange(e,'car_firm')}  placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.applicant}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled type="text" value={release.user} onChange={(e)=>handleChange(e,'user')}  placeholder="" required />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_email}:
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled type="email" value={release.add_email} onChange={(e)=>handleChange(e,'add_email')}  placeholder="" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.car_delivery}:
                </Form.Label>
                <Col sm="9">
                    <InputGroup className="mb-3">
                        <span className="input-group-text"><Icon name="truck-flatbed" className="bigIcon"></Icon></span>
                        <span className="input-group-text">
                            { release.car_delivery ?
                                <Form.Check type="checkbox" value="1" checked="true"  />:
                                <Form.Check type="checkbox" value="1" />
                            }
                            </span>

                    </InputGroup>
                </Col>
            </Form.Group>
            { release.car_delivery ?

                <Form.Group as={Row} className="mb-3" controlId="formReliase">
                    <Form.Label column sm="3">
                        {intl.messages.agreed_price_per_car}:
                    </Form.Label>
                    <Col sm="9">
                        <InputGroup className="mb-3">
                            <Form.Control type="number" value={release.agreed_price} disabled />
                            <span className="input-group-text">{props.currency} {intl.messages.per_car}</span>

                        </InputGroup>
                    </Col>
                </Form.Group> : null }
            { release.car_delivery ?
                <Form.Group as={Row} className="mb-3" controlId="formReliase">
                    <Form.Label column sm="3">
                        {intl.messages.delivery_address}:
                    </Form.Label>
                    <Col sm="9">
                        <Form.Control as="textarea" value={release.delivery_address} disabled />
                    </Col>
                </Form.Group> :
                null
            }
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Form.Label column sm="3">
                    {intl.messages.additional_information}
                </Form.Label>
                <Col sm="9">
                    <Form.Control disabled as="textarea" value={release.description} onChange={(e)=>handleChange(e,'description')}  rows={3} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReliase">
                <Col sm="12">
                    <Row>
                        <Form.Label column sm="12">
                            {intl.messages.containers_on_request}:
                        </Form.Label>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{intl.messages.type}</th>
                                <th>{intl.messages.number_container}</th>
                                <th>{intl.messages.quantity}</th>
                                <th>{intl.messages.car_number}</th>
                                <th>{intl.messages.driver}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {release.containers.map((row, index) =>
                                <ContainerRowOutputEdit key={index}
                                                    index={index}
                                                    row={row}
                                                    del={release.containers.length > 1 ? true: false}
                                />
                            )}

                            </tbody>
                        </Table>
                    </Row>

                </Col>


            </Form.Group>

            <Row className="mb-3 text-align-right" >
                <Col className="d-grid gap-2 d-md-block" >
                    <Button className="mx-md-1 btn-secondary" type={"button"} onClick={back} >{intl.messages.back}</Button>
                    <Button className="mx-md-1 btn-danger btn-block" type={"button"} onClick={(e)=>cancelStatus(e)}>{intl.messages.cancel}</Button>
                    <Button className="mx-md-1 btn-block" type={"submit"}>{intl.messages.save}</Button>
                </Col>

            </Row>
        </Form>

    );
};

export default ReleaseFormOutEdit;
